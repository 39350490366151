import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Navbar, Button } from "react-bootstrap";
// import { LinkContainer } from "react-router-bootstrap";
import "./App.css";
import CustomOAuthButton from './CustomOAuthButton';
import Amplify, {Auth, Hub} from 'aws-amplify';
import config from "./config";
import {authz} from "./authorization";
import Routes from "./Routes";

const oauth = {
  domain: config.cognito.DOMAIN,
  scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
  redirectSignIn: config.cognito.REDIRECT_SIGNIN,
  redirectSignOut: config.cognito.REDIRECT_SIGNOUT,
  responseType: 'code' // or token
};

Amplify.configure({
  Auth: {
    oauth: oauth,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: "query",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: "authz",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      }
    ]
  }
});
// Auth.configure({
//   oauth: oauth,
//   region: config.cognito.REGION,
//   userPoolId: config.cognito.USER_POOL_ID,
//   userPoolWebClientId: config.cognito.APP_CLIENT_ID
// });

class App extends Component {
  constructor(props) {
    super(props);
    this.onHubCapsule = this.onHubCapsule.bind(this);
    this.signOut = this.signOut.bind(this);
    this.refreshAuthNZ = this.refreshAuthNZ.bind(this);
    // let the Hub module listen on Auth events
    Hub.listen('auth', this);
    this.state = {
      authn: 'loading',
    }
  }

  componentDidMount() {
//    console.log('on component mount');
    // check the current user when the App component is loaded
    Auth.currentAuthenticatedUser().then(user => {
      authz().then(res => {
        this.refreshAuthNZ({
          data: {
            authn: 'signedIn',
            authz: res
          }
        });
       // console.log('signed in as: ' + JSON.stringify(user));
      });
    }).catch(e => {
      this.refreshAuthNZ({
        data: {
          authn: 'signIn',
          authz: false
        }
      });
//      console.log('not signed in: ' + e.response);
    });
  }

  onHubCapsule(capsule) {
    // The Auth module will emit events when user signs in, signs out, etc
    const { channel, payload, source } = capsule;
    if (channel === 'auth') {
      switch (payload.event) {
        case 'signIn':
          authz().then(res => {
            this.refreshAuthNZ({
              data: {
                authn: 'signedIn',
                authz: res
              }
            });
//            console.log('signed in');
//            console.log("!!!! state = " + JSON.stringify(this.state));
          });
          break;
        case 'signIn_failure':
          this.refreshAuthNZ({
            data: {
              authn: 'signIn',
              authz: false
            }
          });
//          console.log('not signed in');
//          console.log("!!!! state = " + JSON.stringify(this.state));
          break;
        default:
          break;
      }
    }
  }

  signOut() {
    Auth.signOut().then(() => {
      this.refreshAuthNZ({
        data: {
          authn: 'signIn',
          authz: false
        }
      });
    }).catch(e => {
//      console.log(e);
    });
  }

  // refresh user state
  refreshAuthNZ(res) {
    this.setState({authn: res.data.authn, authz: res.data.authz})
  }

  render() {
    const { authn } = this.state;
    return (
      authn !== 'loading' &&
      <div className="App container">
        <Navbar fluid collapseOnSelect>
          <Navbar.Header>
            <Navbar.Brand>
              <Link to="/">Trellis Hub Kendra Demo</Link>
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            <Navbar.Form pullRight>
              {authn === 'signIn' && <CustomOAuthButton/>}
              {authn === 'signedIn' && <Button bsStyle="primary" onClick={this.signOut}>Logout</Button>}
            </Navbar.Form>
          </Navbar.Collapse>
        </Navbar>
        <Routes childProps={{authn: this.state.authn, authz: this.state.authz}} />
      </div>
    );
  }
}

export default App;
