import React from 'react';
import Node from './Node.js';
import PropTypes from 'prop-types';

const makeRange = (s, e) => Array.from('x'.repeat(e - s), (_, i) => s + i);

class HighlightText extends React.Component {
    constructor(props) {
        super(props);
        // console.log("====> inside constructor <====");
        let rangeArrays = props.ranges.map(
            (r) => {
                return makeRange(r.start, r.end);
            }
        );
        // console.log(rangeArrays);
        this.state = { rangeArrays };
    }

    componentDidUpdate(prevProps) {
        // console.log("====> inside componentDidUpodate <====");
        if (JSON.stringify(this.props.ranges) !== JSON.stringify(prevProps.ranges)) {
            let rangeArrays = this.props.ranges.map(
                (r) => {
                    return makeRange(r.start, r.end);
                }
            );
            // console.log(rangeArrays);
            this.setState({ rangeArrays });
        }    
    }

    rangeRenderer(letterGroup, range, textCharIndex) {
        return this.props.rangeRenderer
          ? this.props.rangeRenderer(letterGroup, range, textCharIndex)
          : letterGroup;
    }

    getRange(charIndex) {
        return this.props.ranges
            && this.props.ranges.find(range => charIndex >= range.start && charIndex <= range.end);
    }

    getRangeNode(start, end, range) {
        return (<Node id={this.props.id}
          range={range}
          startIndex={start}
          endIndex={end}
          key={`${this.props.id}-${start}-${end}`}
          highlightStyle={this.props.highlightStyle}>
          {this.props.text.slice(start, end+1)}
        </Node>);
    }

    getNode(start, end, range) {
        return this.getRangeNode(start, end, range);
    }

    getRanges() {
        const newText = [];
        let node;

        // For all the characters on the text
        let highlightActive = false;
        let textRangeStart = -1;
        let textRangeEnd = -1;
        let range;
        let lastRange;
        console.log(this.props.text);
        for (let textCharIndex = 0;textCharIndex < this.props.text.length;textCharIndex++) {
            lastRange = range;
            range = this.getRange(textCharIndex);
            let foundRange = false;
            for (let i=0;i<this.state.rangeArrays.length;i++) {
                if (this.state.rangeArrays[i].includes(textCharIndex)) {
                    if (!highlightActive) {
                        // push previous non-highlighted node as we're starting a highlighted one
                        if (textRangeStart >= 0 && textRangeEnd >= textRangeStart) {
                            node = this.getNode(textRangeStart, textRangeEnd, lastRange);
                            newText.push(node);
                        }
                        highlightActive = true;
                        textRangeStart = textCharIndex;
                    } else {
                        if (textRangeStart === -1) {
                            textRangeStart = textCharIndex;
                        }
                    }
                    foundRange = true;
                    textRangeEnd = textCharIndex;
                    break;
                }
            }
            if (!foundRange) {
                if (highlightActive) {
                    // highlighted section just ended, so we push it
                    highlightActive = false;
                    node = this.getNode(textRangeStart, textRangeEnd, lastRange)
                    newText.push(node);
                    textRangeStart = textCharIndex;
                } else {
                    if (textRangeStart === -1) {
                        textRangeStart = textCharIndex;
                    }
                }
                textRangeEnd = textCharIndex;
            }
        }
        // push terminal node
        if (textRangeStart >= 0 && textRangeEnd >= textRangeStart) {
            node = this.getNode(textRangeStart, textRangeEnd, range);
            newText.push(node);
        }   
        return newText;
    }

    render() {
        const newText = this.getRanges();
    
        return (
          <div style={this.props.style}>
            {newText}
          </div>
        );
    }
}

HighlightText.propTypes = {
    ranges: PropTypes.array,
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    text: PropTypes.string,
    enabled: PropTypes.bool,
    highlightStyle: PropTypes.object,
    style: PropTypes.object,
    rangeRenderer: PropTypes.func
};

export default HighlightText;