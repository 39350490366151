import React from 'react';
class SearchBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = { term: '', filter: '' };
    }
    onInputChange(term) {
        this.setState({ term });
    }
    onSearchClick() {
        if (this.props.onSearchSubmit) {
            this.props.onSearchSubmit({
                term: this.state.term,
                filter: this.state.filter
            });
        }
    }
    onKeyDown = (e) => {
        if (e.key === 'Enter') {
          this.onSearchClick(e);
        }
    }
    onFilterSelect(filter) {
        this.setState({ filter });
    }

    render() {
        const name = this.props.searchBoxName || undefined;
        return (
            <div className="row">
                <div className="col-md-8">
                    <div className="input-group">
                        <input type="text" className="form-control" placeholder="Type a question..."
                            onChange={event => this.onInputChange(event.target.value)} onKeyDown={this.onKeyDown} />
                        <div className="input-group-btn">
                            <div>
                                <button className="btn btn-default" type="button" onClick={event => this.onSearchClick(event)}>Search</button>
                                <div class="btn-group">
                                    <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Filter <span class="caret"></span></button>
                                    <ul className="dropdown-menu">
                                        <li><a href="#" onClick={() => this.onFilterSelect('')}>Clear</a></li>
                                        <li><a href="#" onClick={() => this.onFilterSelect('finaid')}>Financial Aid</a></li>
                                        <li><a href="#" onClick={() => this.onFilterSelect('bursar')}>Bursar</a></li>
                                        <li><a href="#" onClick={() => this.onFilterSelect('library')}>Libraries</a></li>
                                        <li><a href="#" onClick={() => this.onFilterSelect('bookstore')}>UA Bookstores</a></li>
                                        <li><a href="#" onClick={() => this.onFilterSelect('catcash')}>CatCash</a></li>
                                        <li><a href="#" onClick={() => this.onFilterSelect('mealplans')}>Meal Plans</a></li>
                                        <li><a href="#" onClick={() => this.onFilterSelect('collegeinfo')}>College Info</a></li>
                                        <li><a href="#" onClick={() => this.onFilterSelect('deanofstudents')}>Dean of Students</a></li>
                                        <li><a href="#" onClick={() => this.onFilterSelect('registrar')}>Registrar</a></li>
                                        <li><a href="#" onClick={() => this.onFilterSelect('advising')}>Advising</a></li>
                                        <li><a href="#" onClick={() => this.onFilterSelect('oscr')}>OSCR</a></li>
                                        <li><a href="#" onClick={() => this.onFilterSelect('parking')}>Parking</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {(this.state.filter) && <div className="mt-1 h4 text-sage">Filter: {this.state.filter}</div>}
                </div>
            </div>
        );
    }
}

export default SearchBar;