const dev = {
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://jte7a45oz3.execute-api.us-west-2.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_7bh88R69v",
    APP_CLIENT_ID: "34o6k0t43bo9h9rcs2bko2goos",
    DOMAIN: "trellis-test-shibidp.auth.us-west-2.amazoncognito.com",
    REDIRECT_SIGNIN: "https://kendra-demo.test.trellis.arizona.edu/",
    REDIRECT_SIGNOUT: "https://kendra-demo.test.trellis.arizona.edu/"
  }
};

const prod = {
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://lrsg7pdei4.execute-api.us-west-2.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_aaWKEbq2y",
    APP_CLIENT_ID: "4m64eoslaqhsh588jeen078gi3",
    DOMAIN: "ua-trellis-crmdict.auth.us-west-2.amazoncognito.com",
    REDIRECT_SIGNIN: "https://crmdict.trellis.arizona.edu/",
    REDIRECT_SIGNOUT: "https://crmdict.trellis.arizona.edu/"
  }
};

const config = process.env.REACT_APP_STAGE === 'production'
  ? prod
  : dev;

export default {
  apiGateway: config.apiGateway,
  cognito: config.cognito
};