import React, { Component } from "react";
import { PageHeader, ListGroup, ListGroupItem, Alert } from "react-bootstrap";
import { Auth, API } from 'aws-amplify';
import SearchBar from '../components/SearchBar';
import HighlightText from '../components/HighlightText';
import Spinner from 'react-spinkit';

// import { WithContext as ReactTags } from 'react-tag-input';
// import _ from 'lodash';

import "../css/Home.css";

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.onSearch = this.onSearch.bind(this);

    this.state = {
      searchResults: [],
      isLoading: false
    };
  }

  async componentWillReceiveProps(props) {
    if (this.props !== props) {
      // console.log("*** inside componentWillReceiveProps()");
      // console.log("componentWillReceiveProps: prev state = " + JSON.stringify(this.state));
      if (props.authn !== 'signedIn') {
        return;
      }
      this.setState({ isLoading: false });
      // await this.refreshTables();
      // this.setState({isLoading: false});
    }
//    console.log("componentWillReceiveProps: new state = " + JSON.stringify(this.state));
  }

  async componentDidMount() {
    if (this.props.authn !== 'signedIn') {
      return;
    }
    // // console.log("*** inside componentDidMount()");
    // this.setState({isLoading: true});
    // await this.refreshTables();
    // this.setState({isLoading: false});
 //    console.log("componentDidMount: state = " + JSON.stringify(this.state));
  }

  onSearch(searchEvent) {
    return Auth.currentSession().then(session => {
      const token = session.idToken.jwtToken;
      let myInit = { // OPTIONAL
        headers: {
          Authorization: token,
          'Content-type': 'application/json'
        },
        body: {
          query: searchEvent.term,
          filter: searchEvent.filter
        }
      }
      this.setState({isLoading: true});
      return API.post("query", "/query", myInit)
      .then(res => {
        this.setState({
          isLoading: false,
          searchResults: res
        });
      });
    }).catch(error => {
      this.setState({isLoading: false});
      console.log("Error in Auth.currentSession: " + error);
      return [];
    });
  }

  renderSearchResultsList(results) {
//    console.log("*** in renderTablesList");
//    console.log("----> tables = " + JSON.stringify(tables));
    return results.map(
      (r) => {
        let restype = r.type;
        let uniqId = r.id;
        let text = r.text;
        let category = r.category

        // if (table.parameters.tags.length > 0) {
        //   let tag_array = table.parameters.tags.split(',');
        //   for(var i = 0; i < tag_array.length; i++) {
        //       let clean_text = tag_array[i].replace(/^\s*/, "").replace(/\s*$/, "");
        //       tags[i] = {id: i, text: clean_text};
        //   }
        // }
        let ranges = r.highlights.map(
          (r) => {
            return {
              start: r.BeginOffset,
              end: r.EndOffset
            }
          }
        );
        return (
          <ListGroupItem>
            <div className="row mb-2">
              <div className="col-xs-12 col-md-8">
                <span className="text-size-h4 text-blue30w"><a target="_blank" href={r.url}>{r.title}</a></span>
              </div>
              <div className="col-xs-3 col-md-2">
                <span title="response type" className={`label label-${restype === "answer" ? "success" : "primary"} ml-0`}>{restype}</span>
              </div>
              <div className="col-xs-3 col-md-2">
                <span title="category" className="label label-info ml-0">{category}</span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <small className="text-silver-dark">
                  <HighlightText
                    ranges={ranges}
                    enabled={false}
                    id={uniqId}
                    highlightStyle={{
                      backgroundColor: '#ffea0c',
                      fontWeight: 'bold'
                    }}
                    style={{
                      backgroundColor: '#ffffff'
                    }}
                    text={text}
                  />
                </small>
              </div>
            </div>
          </ListGroupItem>
        );
      }
    );
  }

  renderLander() {
    return (
      <div className="lander">
        <h1>Trellis Hub Kendra Demo</h1>
        <p>The Kendra Demo is a proof-of-concept application showing how enterprise information assets can be searched via a natural language interface.</p>
      </div>
    );
  }

  renderSearchBar() {
    return (
      <div className="searchbar">
        <SearchBar searchBoxName={"querybox"} onSearchSubmit={this.onSearch} />
        {(this.props.authn === 'signedIn' && this.props.authz && this.state.isLoading) && <div className="mt-2"><Spinner name="three-bounce" color="#ab031f" fadeIn="quarter"/></div>}
      </div>
    );
  }

  renderSearchResults() {
    return (
      <div className="searchResults">
        <PageHeader>Query Results</PageHeader>
        <div className="container">
          <div className="row">
            <ListGroup>
              {this.renderSearchResultsList(this.state.searchResults)}
            </ListGroup>
          </div>
        </div>
      </div>
    );
  }

  renderUnauthorized() {
    return (
      <div className="lander">
        <h1>Trellis Hub Kendra Demo</h1>
        <p>The Kendra Demo is a proof-of-concept application showing how enterprise information assets can be searched via a natural language interface.</p>
        <Alert bsStyle="danger">You do not have the appropriate permissions to use this application.</Alert>
      </div>
    );
  } 

  render() {
    return (
      <div className="Home">
        {(this.props.authn === 'signedIn' && this.props.authz) && this.renderSearchBar()}
        {(this.props.authn === 'signedIn' && this.props.authz && !this.state.isLoading && this.state.searchResults.length > 0) && this.renderSearchResults()}
        {(this.props.authn === 'signedIn' && !this.props.authz) && this.renderUnauthorized()}
        {(this.props.authn !== 'signedIn') && this.renderLander()}
      </div>
    );
  }
}
